:root {
  --i: 0;
  --deg: 0;
  --nagDeg: 0;
}

/*  */
.picker-dial {
  width: 113px;
  height: 113px;
  border-radius: 100%;
  background-color: #edeeef;
  border: 2px solid #edeeef;
  position: relative;
  z-index: 1;
}

.picker-dial::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #001f38;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 10rem;
}

.dial-sector {
  height: 50%;
  left: 50%;
  transform: translateX(-50%) rotate(calc(var(--deg) * var(--i)));
  transform-origin: bottom center;
  position: absolute;
}

.dial-sector.min-dial-sector {
  transform: translateX(-50%) rotate(calc(var(--deg) * var(--i)));
}

.sector-line-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10%;
  height: 90%;
  z-index: -1;
  /* cursor: move; */
}

.hr-24-dial-sector .sector-line-wrapper {
  height: 56%;
  top: 44%;
}

.sector-line-wrapper::before {
  content: '';
  width: 17px;
  height: 17px;
  background-color: #ffb92c;
  position: absolute;
  top: -5.5px;
  left: -2px;
  border-radius: 99px;
  /* cursor: move !important; */
  z-index: 1;
}

.sector-line {
  width: 1px;
  background-color: #001f38;
  height: 100%;
  margin: 0 6px;
}

.sector-val {
  position: absolute;
  text-align: center;
  width: 17px;
  height: 17px;
  padding: 5px;
  background-color: transparent;
  color: #001f38;
  left: 50%;
  transform: translateX(-50%) rotate(calc(var(--nagDeg) * var(--i)));
  border-radius: 10rem;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.sector-val.min-sector-val {
  transform: translateX(-50%) rotate(calc(var(--nagDeg) * var(--i)));
}


.hr-24-dial-sector .sector-val {
  transform: translateX(-50%) translateY(18px) rotate(calc(var(--nagDeg) * var(--i)));
  opacity: 0.75;
  font-size: 10px;
}

.hide-min-dial-sector {
  z-index: -1;
}

.sector-val-hover:hover:not(.active-number) {
  background-color: #07b9ec;
  color: #ffffff;
}

.active-number {
  color: white;
}

.active-number.min-active-number {
  visibility: visible !important;
  font-size: 0;
  width: 3px;
  height: 3px;
  background-color: #ffffff;
  border-radius: 99px;
  padding: 0;
  top: 7.1px;
  left: 0.5px;
}

.picker-dial:focus,
.picker-dial.active-dial {
  outline: 0;
  box-shadow: 0px 0px 6px #07b9ec;
}

/* 
.pin {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  pointer-events: none;
} */